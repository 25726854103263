<template>
  <div class="gardenName">
    <div>
      <!--搜索-->
      <div class="searchBox">
        <el-form ref="schoolForm" :inline="true" :model="listParam" class="demo-form-inline">
          <el-form-item label="园所名称">
            <el-input v-model="listParam.schoolName" placeholder="请选择园所名称"></el-input>
          </el-form-item>
          <el-form-item label="备案号" class="marginLeft">
            <el-input v-model="listParam.schoolNo" placeholder="请选择备案号"></el-input>
          </el-form-item>
          <el-form-item label="园所类型" class="marginLeft">
            <el-select v-model="listParam.schoolType" placeholder="请选择园所类型">
              <el-option label="公立" value="0"></el-option>
              <el-option label="私立" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="请选择省" >
            <el-select v-model="listParam.province"
                       @change="label => changeArea('province', label)"
                       clearable placeholder="请选择省" >
              <el-option
                  v-for="(p, i) in areasProvince" :key="`area-province-opt-${i}`"
                  :value="p.value"
                  :label="p.label">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="请选择市" multiple class="marginLeft">
            <el-select v-model="listParam.city"
                       @change="label => changeArea('city', label)"
                       clearable placeholder="请选择市">
              <el-option
                  v-for="(p, i) in cities" :key="`area-city-opt-${i}`"
                  :value="p.label"
                  :label="p.label">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="请选择区/县" class="marginLeft">
            <el-select v-model="listParam.area"
                       @change="label => changeArea('country', label)"
                       clearable placeholder="请选择区/县">
              <el-option
                  v-for="(p, i) in areas" :key="`area-country-opt-${i}`"
                  :value="p.label"
                  :label="p.label">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="园所注册时间 ">
            <el-date-picker
                v-model="time"
                width="200"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item class="exportBtn">
            <el-button type="primary" class="export"  @click="schoolSearch">查询</el-button>
            <el-button  class="export"  @click="reset">重置</el-button>
            <el-button  class="export"  @click="getExpor">导出</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--幼儿园信息列表-->
      <div class="box-shadow">
        <el-table stripe :data="schoolList" style="width: 100%;">
          <el-table-column
              :resizable="false"
              show-overflow-tooltip
              type=index
              width="70"
              label="序号">
            <template slot-scope="scope">
              {{(pageParams.page - 1 ) * pageParams.size + scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column
              v-for="h in schoolHeaders[listParam.type]" :key="`tab-col-${h.prop}`"
              show-overflow-tooltip
              :prop="h.prop"
              :label="h.label"
              :width="h.width ? h.width: ''">
            <template  slot-scope="scope">
              <div v-if="h.prop=== 'schoolType'">{{schoolTypes[scope.row.schoolType]}}</div>
              <div v-else-if="h.prop=== 'studentCount'">{{formatNumber(scope.row.studentCount)}}</div>
              <div v-else-if="h.prop=== 'scores'">{{formatNumber(scope.row.scores)}}</div>
              <div v-else>{{scope.row[h.prop] }}</div>
            </template>
          </el-table-column>
          <el-table-column
              :resizable="false"
              show-overflow-tooltip
              prop="address"
              width="60"
              label="操作">
            <template slot-scope="scope">
              <el-button
                  @click="showKindergarten(scope.row.schoolId)"
                  size="mini">查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--全部统计-->
    <div class="boxPaddingNone">
      <el-table class="box-shadow" v-if="listParam.type== 0" :data="listNew" :show-header="false"  stripe style="width:100%;margin-top: 20px;margin-bottom: 20px"  >
        <el-table-column
            show-overflow-tooltip
            prop="全部"
            label="全部">
          <template>
            <span class="emAll">统计</span>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            width="140"
            label="园所名称">
          <template slot-scope="scope">
            <span style="color:#333;font-weight: bold">共计{{formatNumber(scope.row.schoolCount)}}所幼儿园</span>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            width="10"
            label="案备号">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="省">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="市">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="province"
            label="区/县">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="园所类型">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            width="120"
            label="在园幼儿数">
          <template slot-scope="scope">
            <div>在园幼儿数{{formatNumber(scope.row.studentCount)}}</div>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="注册时间">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            width="120"
            label="积分">
          <template slot-scope="scope">
            <div>积分{{formatNumber(scope.row.scores)}}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-table class="box-shadow" v-if="listParam.type== 1" :data="listNew" :show-header="false" stripe style="width:100%;margin-top: 20px;margin-bottom: 20px" >
        <el-table-column
            show-overflow-tooltip
            prop="全部"
            label="全部">
          <template>
            <span class="emAll">统计</span>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            wdith="140"
            label="园所名称">
          <template slot-scope="scope">
            <span style="color:#333;font-weight: bold">共计{{formatNumber(scope.row.schoolCount)}}所幼儿园</span>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            width="10"
            label="案备号">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            width="10"
            label="省">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="市">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="province"
            label="区/县">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="园所类型">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="年度">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="在园幼儿数">
          <template slot-scope="scope">
            <div>在园幼儿数{{formatNumber(scope.row.studentCount)}}</div>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="积分">
          <template slot-scope="scope">
            <div>积分{{formatNumber(scope.row.scores)}}</div>
          </template>
        </el-table-column>
<!--        <el-table-column-->
<!--            :resizable="false"-->
<!--            show-overflow-tooltip-->
<!--            prop="address"-->
<!--            label="操作">-->
<!--        </el-table-column>-->
      </el-table>
      <el-table class="box-shadow" v-if="listParam.type== 2"  :data="listNew" :show-header="false"  stripe style="width:100%;margin-top: 20px;margin-bottom: 20px"  >
        <el-table-column
            show-overflow-tooltip
            prop="全部"
            label="全部">
          <template>
            <span class="emAll">统计</span>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            wdith="140"
            label="园所名称">
          <template slot-scope="scope">
            <span style="color:#333;font-weight:bold">共计{{formatNumber(scope.row.schoolCount)}}所幼儿园</span>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            width="10"
            label="案备号">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="省">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="市">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="province"
            label="区/县">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="园所类型">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="年度">
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="在园幼儿数">
          <template slot-scope="scope">
            <div>在园幼儿数{{formatNumber(scope.row.studentCount)}}</div>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop=""
            label="积分">
          <template slot-scope="scope">
            <div>积分{{formatNumber(scope.row.scores)}}</div>
          </template>
        </el-table-column>
<!--        <el-table-column-->
<!--            :resizable="false"-->
<!--            show-overflow-tooltip-->
<!--            prop="address"-->
<!--            label="操作">-->
<!--        </el-table-column>-->
      </el-table>
      <el-table class="box-shadow" v-if="listParam.type== 3" :data="listNew" :show-header="false"  stripe style="width:100%;margin-top: 20px;margin-bottom: 20px"  >
      <el-table-column
          show-overflow-tooltip
          prop="全部"
          label="全部">
        <template>
          <span class="emAll">统计</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          prop=""
          wdith="140"
          label="园所名称">
        <template slot-scope="scope">
          <span style="color:#333;font-weight: bold">共计{{formatNumber(scope.row.schoolCount)}}所幼儿园</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          prop=""
          width="10"
          label="案备号">
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          prop=""
          label="省">
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          prop=""
          label="市">
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          prop="province"
          label="区/县">
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          prop=""
          label="园所类型">
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          prop=""
          label="在园幼儿数">
        <template slot-scope="scope">
          <div>在园幼儿数{{formatNumber(scope.row.studentCount)}}</div>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip
          prop=""
          label="积分">
        <template slot-scope="scope">
          <div>积分{{formatNumber(scope.row.scores)}}</div>
        </template>
      </el-table-column>
<!--      <el-table-column-->
<!--          :resizable="false"-->
<!--          show-overflow-tooltip-->
<!--          prop="address"-->
<!--          label="操作">-->
<!--      </el-table-column>-->
    </el-table>
    </div>
    <comPagination  :params="pageParams" @changePage="changePage"></comPagination>
  </div>
</template>

<script>
import comPagination from "@/components/admin/pagination";
import supervise from "@/api/supervise";
import { formatTime } from '@/utils/tools'

export default {
  name: "gardenTable",
  components: {
    comPagination
  },
  //计算属性
  computed: {
    rank() {
      return this.$store.state.userInfo.superviseRank
    },
  },
  data() {
    return {
      isCountry:null,
      // 当前选中标签的名字
      activeTabName: 'first',

      // 园所信息统计
      schoolListAll:[],
      listNew: [{}],

      // 园所列表查询参数
      listParam: {
        schoolName:'', //学校名称
        schoolNo :'',//备案号
        type:'' ,// 组特园 3示范园4 公益园5,全部时不填
        schoolType:'', // 0公立 1私立
        begin:'',//注册时间开始 yyyy-MM-dd
        end:'',//注册结束时间
        province :'',//省
        city:'', //市
        area:'',//区县
      },
      time: [], //注册时间开始 yyyy-MM-dd

      // 督导信息分页参数
      pageParams: {
        page: 1,
        size: 10,
        total: 0
      },

      // 是否显示 督导信息列表数据 还是 园所信息列表数据
      showTable: true,

      // 时间下拉框选项
      dataTypes: [
        {value: 1, label: '2019年'},
        {value: 2, label: '2020年'},
        {value: 3, label: '2021年'},
      ],

      // 地区下拉框选项：各个省份
      areasProvince:[], // 省
      cities:[], // 市
      areas: [], // 县

      allAreas: {}, // 所有地区

      schoolList:[],
      // 幼儿园列表表头
      schoolHeaders: {
        // 全国幼儿园
        0: [
          {prop: 'schoolName', label: '园所名称',width:'130px'},
          {prop: 'schoolNo', label: '备案号'},
          {prop: 'province', label: '省'},
          {prop: 'city', label: '市'},
          {prop: 'area', label: '区县'},
          {prop: 'schoolType', label: '园所类型'},//schoolCreditType :  //3: 足特园 4：示范园 5：公益园
          {prop: 'studentCount', label: '在园幼儿数'},
          {prop: 'registerDate', label: '园所注册时间'},
          {prop: 'scores', label: '积分'},
        ],

        // 足特幼儿园表头
        1: [
          {prop: 'schoolName', label: '园所名称',width:'130px'},
          {prop: 'schoolNo', label: '备案号'},
          {prop: 'province', label: '省'},
          {prop: 'city', label: '市'},
          {prop: 'area', label: '区县'},
          {prop: 'schoolType', label: '园所类型'},  //schoolCreditType :  //3: 足特园 4：示范园 5：公益园
          {prop: 'year', label: '年度'},
          {prop: 'studentCount', label: '在园幼儿数'},
          {prop: 'scores', label: '积分'},
        ],

        // 示范园幼儿园表头
        2: [
          {prop: 'schoolName', label: '园所名称',width:'130px'},
          {prop: 'schoolNo', label: '备案号'},
          {prop: 'province', label: '省'},
          {prop: 'city', label: '市'},
          {prop: 'area', label: '区县'},
          {prop: 'schoolType', label: '园所类型'},  //schoolCreditType :  //3: 足特园 4：示范园 5：公益园
          {prop: 'year', label: '年度'},
          {prop: 'studentCount', label: '在园幼儿数'},
          {prop: 'scores', label: '积分'},
        ],

        // 公益园幼儿园表头
        3: [
          {prop: 'schoolName', label: '园所名称',width:'130px'},
          {prop: 'schoolNo', label: '备案号'},
          {prop: 'province', label: '省'},
          {prop: 'city', label: '市'},
          {prop: 'area', label: '区县'},
          {prop: 'schoolType', label: '园所类型'},  //schoolCreditType :  //3: 足特园 4：示范园 5：公益园
          {prop: 'studentCount', label: '在园幼儿数'},
          {prop: 'scores', label: '积分'},
        ],
      },
      schoolTypes:{
        '0': '公立',
        '1':'私立'
      },
      types:{
        '0': '0',
        '1': '3',
        '2': '4',
        '3': '5'
      }
    }
  },
  methods: {
    // arraySpanMethod({rowIndex, columnIndex }) {
    //   console.log('rowIndex',rowIndex)
    //   console.log('columnIndex',columnIndex)
    //   if (rowIndex % 2 === 0) {
    //     if (columnIndex === 0) {
    //       return [1, 2];
    //     } else if (columnIndex === 1) {
    //       return [3, 4];
    //     }
    //   }
    // },


    changeArea(type, newAreaLabel) {
      console.log('ddfsdfs')
      console.log('change %s to %s', type, newAreaLabel)

      // 是不是直辖市
      const isDirectlyCity = this.isDirectlyCity(newAreaLabel)
      let pid = 0

      switch (type) {
        case 'province':
          // 当切换省份时，更新城市列表
          if (isDirectlyCity) {
            pid = `${this.allAreas[newAreaLabel].value}01`
            this.cities = [{value: newAreaLabel, label: newAreaLabel, id: pid}]
          } else {
            this.cities = (this.allAreas[newAreaLabel].children || []).map(city => {
              return {value: city.value, label: city.label, id: city.value}
            })
          }

          this.listParam.city = '' // 清空选中的市
          this.listParam.area = '' // 清空选中的市
          break;

        case 'city':
          // 当切换城市时，更新区县列表
          if (isDirectlyCity) {
            pid = `${this.allAreas[newAreaLabel].value}01`
           } else {
            const city = this.cities.filter(city => city.label === newAreaLabel) // 选中的城市
            pid = city ? city[0].id : 0
           }
          this.areas= []
          this.getCounties(pid).then(counties => {
            counties.forEach(c => {
              this.areas.push({value: c.name, label: c.name})
            })
          });
           this.listParam.area = '' // 清空选中的县
          break;
        case 'country':
          break;
      }
    },
    // getAreasList(city){
    //   console.log('citydata',city)
    //   const data ={pid: city[0].value}
    //   supervise.cascadedPid(data).then((res)=>{
    //   this.listParam.area = ''
    //     this.areas = res.data.map((item)=>{
    //       return {value: item.pid, label: item.name}
    //     })
    //   })
    // },

    // 获取县列表
    getCounties(pid) {
      return supervise.cascadedPid({pid}).then(res => {
        return res.data
      })
    },

    /** 翻页回调 */
    changePage(pageNum = 1) {
      this.pageParams.page = pageNum
      this.getSchoolInfoList()
      this.getSchoolStatistica()
    },
    //搜索参数
    getSchoolListParam() {
      console.log('3')
      console.log('区县没有值？',this.listParam.area)
      const begin = formatTime(this.time ? this.time[0] || 0 : 0, 'yyyy-MM-dd')
      const end = formatTime(this.time ? this.time[1] || 0 : 0, 'yyyy-MM-dd')
      const province = this.listParam.province || this.$route.query.province
      const city = this.isDirectlyCity(province) ? province : (this.listParam.city ||this.$route.query.city)
      // // const area = this.isDirectlyCity(province) ? this.$route.query.city:this.listParam.area
      //  const area = this.isDirectlyCity(province) ? (this.$route.query.city || this.listParam.area) : this.listParam.area
      const area = this.listParam.area || this.$route.query.area

      return {
        schoolName: this.listParam.schoolName,
        schoolNo:this.listParam.schoolNo,
        // type: this.listParam.type,
        type: this.types[this.$route.query.type],
        schoolType:this.listParam.schoolType,
        begin,
        end,
        province,
        city,
        area,
      }
    },

    //园所信息列表
    getSchoolInfoList(){
      let data = this.getSchoolListParam()
      console.log('/园所信息列表参数',data)
      supervise.getSuperviseSchoolInfos(this.pageParams, data).then((res)=>{
        this.schoolList = res.data.list
        this.pageParams.total = res.data.total
      })
    },

    // 通过省份的label判断其是不是直辖市
    isDirectlyCity(provinceLabel) {
      return ['北京市', '天津市', '重庆市', '上海市'].indexOf(provinceLabel) > -1;
    },

    //园所统计信息
    getSchoolStatistica(){
      let data = this.getSchoolListParam()
      supervise.getSuperviseSchoolSumInfos(data).then((res)=>{
        console.log('res.data',res.data)
        this.schoolListAll = res.data

        this.schoolListAll = res.data
        for (const i in this.schoolListAll) {
          this.$set(this.listNew[0], i, this.schoolListAll[i])
        }
      })
    },



    //全国省份列表
    async getAreas() {
      const self = this
      const userProvinceLabel = this.$route.query.province //用户督导的省份
      const isDirectlyCity = this.isDirectlyCity(userProvinceLabel) // 用户督导的省份是不是直辖市
      const userCityLabel = isDirectlyCity ? userProvinceLabel : this.$route.query.city // 用户督导的城市
      // 县：如果是直辖市通过city传县的名字，不是直辖市通过area传县的名字
      const userCountryLabel = isDirectlyCity ? this.$route.query.city : this.$route.query.area

      this.areasProvince = [] // 省
      this.cities = [] // 市
      this.areas = [] // 县

      // 这个API会一次返回所有的省市列表
      // 所在在this.allAreas中缓存一下，没有获取过的时候去获取，已经获取过就直接在this.allAreas中取数据
      if (!Object.keys(this.allAreas).length) {
        await this.api.datav.cascaded().then(res => {
          res.data.forEach(province => {
            self.allAreas[province.label] = province
          })
        })
      }


      // 处理列表参数
      this.listParam.province = userProvinceLabel ? userProvinceLabel : this.listParam.province
      this.listParam.city = userCityLabel ? userCityLabel : this.listParam.city
      this.listParam.area = userCountryLabel ? userCountryLabel : this.listParam.area

      console.log('区县值', this.listParam.area)




      // 取省份列表
      Object.values(this.allAreas).forEach(province => {
        // 如果传入了用户省份，则只能选这一个省
        if (!userProvinceLabel || userProvinceLabel === province.label) {
          this.areasProvince.push({value: province.label, label: province.label})
        }
      })
      if(userProvinceLabel == '新疆生产建设兵团'){
        return
      }

      // 取城市列表
      // 只有传入了用户省份时取这个省份对应的城市列表，如果没传省份就都可以选（列表为空）。
      // 这里需要处理一下直辖市：是直辖市的时候直接返回直辖市的名字。
      if (userProvinceLabel) {
        if (isDirectlyCity) {
          const pid = `${this.allAreas[userProvinceLabel].value}01`;
          this.cities = [{value: userProvinceLabel, label: userProvinceLabel, id: pid}]
          this.areas = []
          await this.getCounties(pid).then(counties => {
            counties.forEach(c => {
              this.areas.push({value: c.name, label: c.name, id: c.value})
            })
          });
        } else {
          this.allAreas[userProvinceLabel].children.forEach(city => {
            this.cities.push({value: city.label, label: city.label, id: city.value})
          })
        }
      }

      // 如果传入了用户选择的市，则刷新一下区县列表
      if (userCityLabel) {
        console.log('%c userCityLabel', 'color:red',  userCityLabel, this.cities)

        const city = this.cities.filter(city => city.label === userCityLabel) // 选中的城市
        const pid = city ? city[0].id : 0

        await this.getCounties(pid).then(counties => {
          counties.forEach(c => {
            this.areas.push({value: c.name, label: c.name})
          })
        });
      }

      return Promise.resolve(this.allAreas)
    },

    // 搜索
    schoolSearch(){
      this.getSchoolInfoList()
      this.getSchoolStatistica()
    },

    // 查看幼儿园列表
    showKindergarten(schoolId) {
      this.schoolId = schoolId
      // this.setListParamToLocalStorage(this.listParam, this.schoolPage)
      return this.api.admin.schoolSuperviseFitSchool(schoolId).then(() => {
        // self.$store.commit('setUserInfo', {...self.$store.userInfo, schoolId})
        // 还需要跳转到幼儿首页
        this.$store.state.userInfo.schoolId = schoolId;
        let userInfo = this.$store.state.userInfo
        this.$store.commit('setUserInfo',userInfo)
        this.$store.dispatch("getSchoolInfo");
        this.$router.push({
          path: 'school/home',
          meta:{
            keepAlive: true
          }
        });
      })
    },

    //重置
    reset(){
      this.pageParams.page = 1
      this.listParam.schoolName= ''  //园所名称
      this.listParam.schoolNo ='' //园所备案号
      this.listParam.schoolType=''// 园所类型
      this.time =[] //注册时间
      this.listParam.province ='' //省
      this.listParam.city=''  //市
      this.listParam.area ='' //区县

      this.getSchoolInfoList()
      this.getSchoolStatistica()
    },
   //导出
    getExpor() {
      const data = this.getSchoolListParam()
      supervise.exportSuperviseSchoolInfos(data).then(res => {
            return this.api.admin.exportSchoolCallback(res.msg).then(res => {
              // window.location.href = res.message
              window.open(res.message)
              this.$notice({
                type: "success",
                message: "导出成功",
              }).isShow();
            })
          })
    },
    //数字每三位加个逗号
    formatNumber (num) {
      var reg=/\d{1,3}(?=(\d{3})+$)/g;
      return (num + '').replace(reg, '$&,');
    },

  },
   mounted() {
     this.listParam.type = this.$route.query.type
     this.getAreas().then(() => {
       console.log('点击查看')
       this.getSchoolInfoList()
       this.getSchoolStatistica()
     })
  }
}
</script>

<style lang="scss" scoped>

/deep/ .demo-form-inline{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/deep/.el-form-item{
  //width: 420px;
  margin-right: 0 !important;
}
/deep/ .el-form--inline .marginLeft {
  //margin-left: 40px !important;
  //margin-right:0 !important;
}
/deep/ .el-select{
  position: relative;
}
/deep/.el-select__tags{
  //position: absolute;
  //top: 80px;
  //left: 10px;
}
.provinceAdds{
  ul{
    margin-top:30px;
    li{
      float: left;
      padding: 5px 10px 5px 15px;
      background: #ED787E;
      border-radius: 12px;
      color: #fff;
      font-size: 14px;
      margin-right: 20px;
      em{
        display: inline-block;
        width: 10px;
        height: 9px;
        background: url(../../assets/img/Icon_Adr_Close.png) no-repeat;
        background-size: cover;
        margin-left: 10px;
        vertical-align: top;
        margin-top: 10px;
        cursor: pointer;
      }
    }
  }
}
.exportBtn{
  position: absolute;
  right: 0;
  bottom: 10px;
  text-align: right;
}
.statistical{
  margin-top: 20px;
  width: 100%;
  height: 54px;
  line-height: 54px;
  background: #FFFFFF;
  box-shadow: 0 2px 12px 0 rgba(189, 189, 189, 0.41);
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  em{
    display: inline-block;
    font-weight: normal;
    font-size: 16px;
    width: 160px;
    height: 54px;
    padding-left: 30px;
    box-sizing: border-box;
    background: url(../../assets/img/PIc_Bg_All.png) no-repeat;
    background-size: cover;
    color: #fff;
    margin-right:100px;
  }
  span{
    display: inline-block;
    text-align: center;
  }
}
.emAll{
  display: inline-block;
  font-size: 16px;
  width: 160px;
  height: 54px;
  line-height: 54px;
  font-weight: bold;
  box-sizing: border-box;
  background: url(../../assets/img/PIc_Bg_All.png) no-repeat;
  background-size: cover;
  color: #fff;
  text-align: left;
  padding-left: 30px;
}
/deep/ .el-table .el-table__cell{
  padding: 12px 10px;
}
/deep/.boxPaddingNone .el-table .el-table__cell{
  padding: 0 !important;
}
/deep/.boxPaddingNone .el-table .cell, .el-table--border .el-table__cell:first-child .cell{
  padding-left: 0 !important;
  font-weight: bold !important;
}

/deep/.el-form-item__label{
  font-size: 18px;
  color: #333333;
  width: 130px!important;
}

/deep/ .el-cascader {
  float: left;
}

/deep/ .el-cascader {
  float: left;
  margin-right: 30px;
}

/deep/ .el-tabs__content {
  overflow: inherit;
}

.box-shadow {
  box-shadow: 0 2px 12px 0 rgba(189, 189, 189, 0.41);
}

.searchBox {
  position: relative;
  margin-bottom: 20px;
}

.export {
  width: 112px;
  height: 56px;
  line-height: 56px;
  color: #fff;
  font-size: 18px;
  background: url(../../assets/img/superPic/Btn_Bg_PK.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  cursor: pointer;
  border:none;
}

/deep/ .el-button el-button--default el-button--mini {
  color: #fff;
}
.searchBtn {
  float: left;
  width: 50px;
  height: 56px;
  background: url(../../assets/img/superPic/Btn_Bg_Serch.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  position: relative;
  top: -7px;
  cursor: pointer;

  img {
    display: inline-block;
    margin: 0 auto;
    width: 27px;
    height: 26px;
    margin-top: 13px;
  }
}

.fl {
  float: left;
}

.gardenName {
  width: 1600px;
  margin: 0 auto;
  padding: 41px;
  box-sizing: border-box;
}

/deep/ .el-tabs__header {
  height: 42px;
  line-height: 42px;
  margin: 40px 0;
  background: #FFFFFF;
  border-radius: 2px;
  font-size: 20px;
  font-weight: 400;
  color: #374D86;
}
/deep/.el-tabs__nav-scroll{
  overflow: inherit;
}

/deep/ .el-tabs__nav{
  box-shadow: 0 2px 12px 0 rgba(189, 189, 189, 0.41);
  padding-left: 10px;
  padding-right: 10px;
  height:42px;
  //position: relative;

}

/deep/ .el-tabs--top .el-tabs__item.is-top:last-child {
  padding-right: 20px !important;
}

/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px !important;
}


/deep/.el-tabs__nav-wrap{
  overflow: inherit;
}
/deep/ .el-tabs__nav-wrap::after {

  background: none !important;
}

/deep/ .el-tabs__active-bar {
  width: auto !important;
}

/deep/ .el-tabs__item.is-active {
  height: 48px;
  line-height: 48px;
  background: linear-gradient(232deg, #364B82, #4C66AD);
  border-radius: 6px;
  color: #fff;
  margin-bottom: 4px;
  border-bottom: none;
  position: relative;
  top: -3px;
}

/dee/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: auto !important;
}
/deep/ .el-input--suffix .el-input__inner{
  height: 40px;
}
/deep/ .el-tag.el-tag--info{
  display: inline-block;
}

/deep/ .el-input {

}

/deep/ .el-select {
  float: left;
  margin-right: 30px;
}

/deep/ .el-input, .el-date-editor.el-input__inner {
  width: 250px !important;
  height: 38px !important;
  margin-bottom: 30px;
}

//表格样式
/deep/ .el-table th {
  background: #BA3037;
  color: #fff;
  border: none;
  text-align: center;
}

/deep/ .el-table--enable-row-transition .el-table__body td {
  text-align: center;
  border: none;
  border-bottom: 1px solid #DDDDDD;
  color: #333;
  font-size: 14px;
  line-height: 42px;
}



/deep/ .el-table .operation {
  width: 150px;
}

/deep/.el-button--mini, .el-button--small {
  border: none;
  color: #BA3037;
  font-size: 14px;
  background: none;
}

/deep/ .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #fff !important;
}
/deep/.el-tooltip{
  width:auto !important;
}
</style>
