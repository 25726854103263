import { request } from "@/utils/request";

//  http://10.34.3.149:8090/api/school/superviseInfo/getSuperviseInfo  //督导信息
const getSuperviseInfo = (params) => {
  return request({
    url: "school/superviseInfo/getSuperviseInfo",
    method: "get",
    params: params,
  });
};


//督导省份信息
//省 school/superviseInfo/getSuperviseProvinceInfos/1/1
//市 superviseInfo/getSuperviseCityInfos/1/10
const getSuperviseProvinceInfos = (rank,params,data) => {
  const ranks = {
    1: "getSuperviseProvinceInfos",
    2: "getSuperviseCityInfos",
  };
  return request({
    url: `school/superviseInfo/${ranks[rank]}/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
};

//督导市统计信息
// 省 school/superviseInfo/getSuperviseProvinceSumInfos
// 市 school/superviseInfo/getSuperviseCiytSumInfos
const getSuperviseCiytSumInfos = (rank,params) => {
  const ranks = {
    1: "getSuperviseProvinceSumInfos",  //省统计
    2: "getSuperviseCiytSumInfos", //市统计
  };
  return request({
    url:  `school/superviseInfo/${ranks[rank]}`,
    method: "get",
    params: params,
  });
};

//督导省信息导出
//省导出 school/superviseInfo/exportSuperviseInfos
//市导出 school/superviseInfo/exportSuperviseCityInfos

const getExportSuperviseInfos = (rank, data) => {
  const ranks = {
    1: "exportSuperviseInfos",
    2: "exportSuperviseCityInfos",
  };
  // let url = /school/supervise/school/${ranks[rank]}";
  return request({
    url: `school/superviseInfo/${ranks[rank]}`,
    method: "get",
    params: data,
  });
}
//园所信息列表 /api/school/superviseInfo/getSuperviseSchoolInfos/1/10、
const getSuperviseSchoolInfos= (params,data) => {
  return request({
    url:  `school/superviseInfo/getSuperviseSchoolInfos/${params.page}/${params.size}`,
    method: "get",
    params: data,
  });
}

//园所信息统计 /api/school/superviseInfo/getSuperviseSchoolSumInfos
const getSuperviseSchoolSumInfos= (data) => {
  return request({
    url:  "school/superviseInfo/getSuperviseSchoolSumInfos",
    method: "get",
    params: data,
  });
}

//园所信息导出/api/school/superviseInfo/exportSuperviseSchoolInfos

const exportSuperviseSchoolInfos= (data) => {
  return request({
    url:  "school/superviseInfo/exportSuperviseSchoolInfos",
    method: "get",
    params: data,
  });
}

const cascadedPid = (data) => {
  return request({
    url: `/area/findByPid`,
    method: "get",
    params: data,
  });
};

export default {
  getSuperviseInfo,
  getSuperviseProvinceInfos,
  getSuperviseCiytSumInfos,
  getExportSuperviseInfos,
  getSuperviseSchoolInfos,
  getSuperviseSchoolSumInfos,
  exportSuperviseSchoolInfos,
  cascadedPid
};
