<template>
  <div class="">
    <div class="banner">
      <div class="center">
        <h6 class="region">2021-2022学年积分</h6>
        <p class="area">{{InfoList.scoresStr}}</p>
        <div>
          <span class="regionTime">
            {{ $store.state.userInfo.superviseRank === 1 ?'全国督导':$store.state.userInfo.province }}
          </span>
        </div>
        <ul class="regionList">
          <li>
            <div class="schoolNumer"> <!--falling-->
              <p>{{formatNumber(InfoList.schoolCount)}}</p>
              <span>总注册园所</span>
            </div>
          </li>
          <li>
            <div class="schoolNumer"> <!--falling-->
              <p>{{formatNumber(InfoList.studentCount)}}</p>
              <span>总幼儿数</span>
            </div>
          </li>
          <li>
            <div class="schoolNumer">  <!--falling-->
              <p>{{formatNumber(InfoList.teacherCount)}}</p>
              <span>总教师数</span>
            </div>
          </li>
          <li>
            <div class="schoolNumer"> <!--falling-->
              <p>{{formatNumber(InfoList.schoolZtCount)}}</p>
              <span>足特园数量</span>
            </div>
          </li>
          <li>
            <div class="schoolNumer"> <!--falling-->
              <p>{{formatNumber(InfoList.schoolSfCount)}}</p>
              <span>示范园数量</span>
            </div>
          </li>
          <li>
            <div class="schoolNumer"> <!--falling-->
              <p>{{formatNumber(InfoList.schoolGyCount)}}</p>
              <span>公益园数量</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
<!--    &lt;!&ndash;督导列表&ndash;&gt;-->
<!--    <garden-table></garden-table>-->
    <!--园所列表-->
  <school-table></school-table>
  </div>
</template>
<script>
//import GardenTable from "@/views/supervision/gardenTable";
 import schoolTable from "@/views/supervision/schoolTable";
import supervise from "@/api/supervise";
export default{
  components: {
    //GardenTable,
     schoolTable
  },
  data() {
    return{
      InfoList:{},
      provinceName:'',
      dataNumber: -1,
    }
  },
  methods: {
    formatNumber (num) {
      var reg=/\d{1,3}(?=(\d{3})+$)/g;
      return (num + '').replace(reg, '$&,');
    },

    //查看督导总统计
    getSuperviseNational(){
      let that = this
      let data ={
        province : this.$route.query.province
      }
      supervise.getSuperviseInfo(data).then(res =>{
        that.InfoList = res.data
      })
    },
  },
  mounted() {
    this.getSuperviseNational()
  }
}
</script>
<style lang="scss" scoped>
.banner{
  width: 100%;
  height: 575px;
  background: url(../../assets/img/superPic/Pic_bg_banner.png) no-repeat;
  background-size: 100% 100%;
}
.center{
  width: 1434px;
  margin: 0 auto;
  text-align: center;
  padding: 30px 0;
  box-sizing: border-box;
  .region{
    font-size: 18px;
    font-weight: normal;
    color: #FFFFFF;
    opacity: 0.7;
    margin-bottom: 16px;
  }
  .area{
    font-size: 54px;
    font-weight: bold;
    color: #FAC000;
    margin-bottom: 27px;
  }
  .regionTime{
    font-size: 46px;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 42px;
  }
}
.regionList{
  padding-top: 42px;
  overflow: hidden;
  margin-left: -55px;
  li{
    float: left;
    width: 193px;
    margin-left: 55px;
    .schoolNumer{
      color: #fff;
      p{
        padding-top: 68px;
        font-size: 42px;
        font-weight: bold;
        color: #FFFFFF;
        opacity: 0.9;
      }
      span{
        height: 108px;
        line-height: 140px;
        background: url(../../assets/img/superPic/Pic_Bg_Zs.png) no-repeat bottom;
        background-size: 100% 100%;
        display: block;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        opacity: 0.9;
        //margin-top: 18px;

      }
    }
    .falling{
      width:  193px;
      height: 172px;
      background: url(../../assets/img/superPic/Pic_Bg_M.png) no-repeat;
      background-size: 100% 100%;
      color: #fff;
      p{
        padding-top: 68px;
        font-size: 42px;
        font-weight: bold;
        color: #FFFFFF;
        opacity: 0.9;
      }
      span{
        display: block;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        opacity: 0.9;
        margin-top: 18px;
      }
    }
    .ranking{
      margin-top: 4px;
      color: #fff;
      font-size: 17px;
      font-weight: bold;
      opacity: 0.9;
      em{
        color: #BA3037;
      }
      .blue{
        color: #617FD1;
      }
    }
  }
}

.gardenName{
  overflow: hidden;
}
</style>
